import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout-journal'
import cover from './this-seeming-chaos-med.jpg'
import { book, bookBlock, bookInfo } from '../../components/layout.module.css'

const ThisSeemingChaos = () => {
  return (
	<Layout pageTitle="This Seeming Chaos">
		<div>
			<div className={bookBlock}>
				<img className={book} src={cover} alt="Book cover: This Seeming Chaos by Sarah Avenir" />
				<p className={bookInfo}>
					<a href={'/This-Seeming-Chaos-by-Sarah-Avenir.mobi'}>Kindle</a>&nbsp; • &nbsp;
					<a href={'/This-Seeming-Chaos-by-Sarah-Avenir.epub'}>ePub</a>&nbsp; • &nbsp;
					<Link to="/this-seeming-chaos/read">web</Link>
				</p>
			</div>
			<p>These journals were not written with the intent to publish. I added nothing and only made words more specific when the subject was implied in my head but not specified for a reader. I cut out anything too repetitive, TMI, or not relevant to my purposes.</p>
			<p>If I were a member of Charles Darwin's family revising and publishing his autobiography posthumously, this might be considered a "bowdlerized" version but luckily I am not and it's just helpful editing.</p>
			<br />
 		</div>
	</Layout>
  )
}


export default ThisSeemingChaos